
import { defineComponent } from 'vue'

import iStatusWaiting from '@/assets/icons/statuses/StatusWaiting.svg'
import iStatusInProgress from '@/assets/icons/statuses/StatusInProgress.svg'
import iStatusCompleted from '@/assets/icons/statuses/StatusCompleted.svg'
import iStatusCanceled from '@/assets/icons/statuses/StatusCanceled.svg'

import {
    FTL_STATUS_SEARCHING_TOKEN,
    FTL_STATUS_WAIT_CONFIRM_TOKEN,
    FTL_STATUS_ACCEPTED_TOKEN,
    FTL_STATUS_CANCELED_TOKEN,
} from '@/core/constants/FTL.constants'

export default defineComponent({
    components: {
        'icon-status-waiting': iStatusWaiting,
        'icon-status-in-progress': iStatusInProgress,
        'icon-status-completed': iStatusCompleted,
        'icon-status-canceled': iStatusCanceled,
    },
    props: {
        statusCode: {
            type: String,
            default: null,
        },
        statusText: {
            type: String,
            default: null,
        },
    },
    setup() {
        return {
            FTL_STATUS_SEARCHING_TOKEN,
            FTL_STATUS_WAIT_CONFIRM_TOKEN,
            FTL_STATUS_ACCEPTED_TOKEN,
            FTL_STATUS_CANCELED_TOKEN,
        }
    },
})
