
import { computed, defineComponent, onMounted, PropType, reactive } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import LTLStatus from '@/modules/HistoryNew/components/LTLStatus/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import SIconButton from '@/common/components/SIconButton/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'

import { LTLShortInvoiceT } from '@/core/types/LTL.types'
import iImage from '@/assets/icons/Image.svg'

import useNotifications from '@/common/composable/useNotifications'
import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { useFile } from '@/common/composable/useFile'

const NOTIFICATIONS_GROUP = 'create-module'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'ltl-status': LTLStatus,
        'icon-refresh': iRefresh,
        SButton,
        'icon-image': iImage,
        's-icon-button': SIconButton,
    },
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        loadingExcel: {
            type: Boolean,
            default: false,
        },
        invoices: {
            type: Array as PropType<LTLShortInvoiceT[]>,
            default: () => [],
        },
        withRefresh: {
            type: Boolean,
            default: true,
        },
        inOrder: {
            type: Boolean,
            default: false,
        },
        isExtended: {
            type: Boolean,
            default: false,
        },
        orderId: {
            type: Number,
            default: null,
        },
    },
    emits: ['refresh', 'mounted', 'excel'],
    setup(props, { emit }) {
        const invoicesAPI = new InvoicesRealisation()
        const notifications = useNotifications()
        const { saveFile } = useFile()

        const state = reactive({
            is_print_loading: false,
        })

        const emitRefresh = () => {
            emit('refresh')
        }

        const emitExcel = () => {
            emit('excel')
        }

        const getCostPrediction = (cost_prediction: number | null, status_code: number) => {
            if (cost_prediction === -1) {
                return 'Нет в биллинге'
            } else if (cost_prediction === null) {
                return 'Будет доступно после обработки груза'
            } else {
                if (status_code === 204) {
                    return '-'
                } else if ([212, 503].includes(status_code)) {
                    return ''
                } else if (status_code === 211) {
                    return cost_prediction + ' ₸'
                } else {
                    return 'от ' + cost_prediction + ' ₸'
                }
            }
        }

        const printPlaces = async () => {
            if (!props.orderId) return

            try {
                state.is_print_loading = true

                const printResponse = await invoicesAPI.getOrderPlaces(props.orderId)

                saveFile(printResponse, props.orderId.toString())
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка печати мест по заказу',
                    },
                    error
                )
            } finally {
                state.is_print_loading = false
            }
        }

        const isTextLong = (text?: string) => text && text.length > 5

        //

        const isBillingEnabled = computed(() => {
            return props.invoices && props.invoices[0] && props.invoices[0].billing_enabled
        })

        const getEighthClass = computed(() => {
            return isBillingEnabled.value ? 'eighth' : 'eighth--regular'
        })

        //

        onMounted(() => {
            emit('mounted')
        })

        //

        return {
            state,
            //
            emitRefresh,
            emitExcel,
            //
            getCostPrediction,
            printPlaces,
            //
            isBillingEnabled,
            getEighthClass,
            //
            isTextLong,
        }
    },
})
