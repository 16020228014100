
import { defineComponent } from 'vue'

// import iStatusWaiting from '@/assets/icons/statuses/StatusWaiting.svg'
import iStatusInProgress from '@/assets/icons/statuses/StatusInProgress.svg'
import iStatusCompleted from '@/assets/icons/statuses/StatusCompleted.svg'
import iStatusCanceled from '@/assets/icons/statuses/StatusCanceled.svg'

export default defineComponent({
    components: {
        // 'icon-status-waiting': iStatusWaiting,
        'icon-status-in-progress': iStatusInProgress,
        'icon-status-completed': iStatusCompleted,
        'icon-status-canceled': iStatusCanceled,
    },
    props: {
        statusCode: {
            type: Number,
            default: null,
        },
        statusText: {
            type: String,
            default: null,
        },
        isInvoice: {
            type: Boolean,
            default: false
        }
    },
})
